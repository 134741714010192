*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Open Sans';
}

ul[class],
ol[class] {
  padding: 0;
}

/* Add open sans font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  outline: none;
  box-shadow: none;
}

select,
option {
  font: -moz-pull-down-menu;
}

input::placeholder {
  color: #a5a5a5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.owl-carousel {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  position: relative;
}

/* SELECT ARROW */

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes OpacityAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
